import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

export interface Props {
  pretitle?: string
  title?: string
  images?: ImageProps[]
  buttons?: ButtonProps[]
}

export const GalleryAccomodations = memo(function GalleryAccomodations({
  pretitle,
  title,
  images,
  buttons,
}: Props) {
  if (!images) return false
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderMain, instanceRefMain] = useKeenSlider({
    loop: true,
    drag: false,
    initial: 0,
    defaultAnimation: {
      duration: 1000,
    },
    breakpoints: {
      '(max-width: 991px)': {
        drag: true,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      let index = s.track.details.rel
    },
  })
  return (
    <Container>
      <MainSlider>
        <StyledFadeInUp>
          <SliderImage ref={sliderMain} className="keen-slider">
            {images.map((item, index) => (
              <SlideImage key={index} className="keen-slider__slide">
                <Image {...item} />
              </SlideImage>
            ))}
          </SliderImage>
          <Overlay />
          <Text>
            {pretitle ? (
              <FadeInUp>
                <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
              </FadeInUp>
            ) : null}
            {title ? <Title text={title} /> : null}
          </Text>
        </StyledFadeInUp>
        <FadeIn>
          <Arrow
            className="arrow-left"
            onClick={(e) => {
              instanceRefMain.current?.prev()
            }}
          />
          <Arrow
            className="arrow-right"
            direction="R"
            onClick={(e) => {
              instanceRefMain.current?.next()
            }}
          />
        </FadeIn>
        {buttons ? (
          <ButtonsList>
            <StyledButton variant="outline-light" {...buttons[0]} />
            <StyledButton variant="outline-light" {...buttons[1]} />
          </ButtonsList>
        ) : null}
      </MainSlider>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.66vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const MainSlider = styled.div`
  width: 100%;
  position: relative;

  .arrow-left {
    top: 50%;
    left: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow-right {
    top: 50%;
    right: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    transform: translate3d(50%, -50%, 0);
  }

  @media (max-width: 991px) {
    height: 100vw;

    .arrow-left {
      border: none;
      background-color: transparent;
      transform: translate3d(0%, -50%, 0);
    }
    .arrow-right {
      border: none;
      background-color: transparent;
      transform: translate3d(0%, -50%, 0);
    }
  }
`

const StyledFadeInUp = styled(FadeInUp)`
  width: 100%;
  height: 100%;
`

const SliderImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`
const SlideImage = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 991px) {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`
const Overlay = styled.div`
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.4;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
`

const Text = styled.div`
  position: absolute;
  top: 6.66vw;
  left: 6.66vw;

  @media (max-width: 991px) {
    top: 30px;
    left: 30px;
  }
`
const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 5vw;
  margin: 3vw 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const ButtonsList = styled.div`
  position: absolute;
  bottom: 6.66vw;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  text-align: center;
  width: 100%;

  @media (max-width: 991px) {
    bottom: 30px;
  }
`
const StyledButton = styled(Button)`
  width: 20vw;
  display: inline-block;
  max-width: none;
  margin: 0 1vw;

  @media (max-width: 991px) {
    width: calc(43% - 30px);
    max-width: auto;
    margin: 0 15px;
  }
`
